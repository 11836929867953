
const mutations = {

  // /////////////////////////////////////////////
  // COMPONENTS
  // /////////////////////////////////////////////

  // Vertical NavMenu

  TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE(state, value) {
    state.isVerticalNavMenuActive = value
  },
  TOGGLE_REDUCE_BUTTON(state, val) {
    state.reduceButton = val
  },
  UPDATE_MAIN_LAYOUT_TYPE(state, val) {
    state.mainLayoutType = val
  },
  UPDATE_VERTICAL_NAV_MENU_ITEMS_MIN(state, val) {
    state.verticalNavMenuItemsMin = val
  },
  UPDATE_VERTICAL_NAV_MENU_WIDTH(state, width) {
    state.verticalNavMenuWidth = width
  },

  // VxAutoSuggest

  UPDATE_STARRED_PAGE(state, payload) {

    // find item index in search list state
    const index = state.navbarSearchAndPinList["pages"].data.findIndex((item) => item.url == payload.url)

    // update the main list
    state.navbarSearchAndPinList["pages"].data[index].is_bookmarked = payload.val

    // if val is true add it to starred else remove
    if (payload.val) {
      state.starredPages.push(state.navbarSearchAndPinList["pages"].data[index])
    }
    else {
      // find item index from starred pages
      const index = state.starredPages.findIndex((item) => item.url == payload.url)

      // remove item using index
      state.starredPages.splice(index, 1)
    }
  },

  // Navbar-Vertical

  ARRANGE_STARRED_PAGES_LIMITED(state, list) {
    const starredPagesMore = state.starredPages.slice(10)
    state.starredPages     = list.concat(starredPagesMore)
  },
  ARRANGE_STARRED_PAGES_MORE(state, list) {
    let downToUp                 = false
    let lastItemInStarredLimited = state.starredPages[10]
    const starredPagesLimited    = state.starredPages.slice(0, 10)
    state.starredPages           = starredPagesLimited.concat(list)

    state.starredPages.slice(0, 10).map((i) => {
      if (list.indexOf(i) > -1) downToUp = true
    })

    if (!downToUp) {
      state.starredPages.splice(10, 0, lastItemInStarredLimited)
    }
  },

  // ////////////////////////////////////////////
  // UI
  // ////////////////////////////////////////////
  TOGGLE_CONTENT_OVERLAY(state, val) { state.bodyOverlay       = val },
  UPDATE_PRIMARY_COLOR(state, val)   { state.themePrimaryColor = val },
  UPDATE_THEME(state, val)           { state.theme             = val },
  UPDATE_WINDOW_WIDTH(state, width)  { state.windowWidth       = width },
  UPDATE_WINDOW_SCROLL_Y(state, val) { state.scrollY = val },


  // /////////////////////////////////////////////
  // User/Account
  // /////////////////////////////////////////////
  LOGOUT_USER(state) {
    localStorage.removeItem(state.localUserKey)
    state.AppActiveUser = null;
    window.location = "/";
  },

  // Updates user info in state and localstorage
  UPDATE_USER_INFO(state, user) {
    if(!user.id){ localStorage.removeItem(state.localUserKey); return};
    state.AppActiveUser = user;
    const get_localUser = localStorage.getItem(state.localUserKey);
    const storedData = JSON.parse(unescape(atob(get_localUser)));
    storedData.user = user;
    localStorage.setItem(state.localUserKey, btoa(escape(JSON.stringify(storedData))));
  },

  ACTIVE_USER_INFO_UPDATED(state, value) {
    state.isActiveUserUpdated = value
  },

  UPDATE_USER_UNREAD_CHAT(state, count) {
    state.activeUserUnreadChats = count
  },

  /************** FOR COUNTRIES *****************/
  ADD_COUNTRY(state, country){
    state.countries.unshift(country);
  },
  SET_COUNTRIES(state, countries){
    state.countries = [...countries];
  },
  UPDATE_COUNTRY(state, country){
    const dataIndex = state.countries.findIndex((f) => f.id == country.id)
     Object.assign(state.countries[dataIndex], country)
  },
  DELETE_COUNTRY(state, countryId){
    const ItemIndex = state.countries.findIndex((item) => item.id == countryId)
    state.countries.splice(ItemIndex, 1);
  },

  /************** FOR LANGUAGES *****************/
  ADD_LANGUAGE(state, language){
    state.languages.unshift(language);
  },
  SET_LANGUAGES(state, languages){
    state.languages = [...languages];
  },
  UPDATE_LANGUAGE(state, language){
    const dataIndex = state.languages.findIndex((f) => f.id == language.id)
    Object.assign(state.languages[dataIndex], language)
  },
  DELETE_LANGUAGE(state, languageId){
    const ItemIndex = state.languages.findIndex((item) => item.id == languageId)
    state.languages.splice(ItemIndex, 1);
  },

  /************** FOR SKILLS *****************/
  ADD_SKILL(state, skill){
    state.skills.unshift(skill);
  },
  SET_SKILLS(state, skills){
    state.skills = [...skills];
  },
  UPDATE_SKILL(state, skill){
    const dataIndex = state.skills.findIndex((f) => f.id == skill.id)
    Object.assign(state.skills[dataIndex], skill)
  },
  DELETE_SKILL(state, skillId){
    const ItemIndex = state.skills.findIndex((item) => item.id == skillId)
    state.skills.splice(ItemIndex, 1);
  },

  /************** FOR COUPONS *****************/
  ADD_COUPON(state, coupon){
    state.coupons.unshift(coupon);
  },
  SET_COUPONS(state, coupons){
    state.coupons = [...coupons];
  },
  UPDATE_COUPON(state, coupon){
    const dataIndex = state.coupons.findIndex((f) => f.id == coupon.id)
    Object.assign(state.coupons[dataIndex], coupon)
  },
  DELETE_COUPON(state, couponId){
    const ItemIndex = state.coupons.findIndex((item) => item.id == couponId)
    state.coupons.splice(ItemIndex, 1);
  },
    /************** FOR RECORDS *****************/
  SET_COMPANY_RECORD(state, record){
    if(record){state.companyRecord = record}
  },

  /************** FOR REVIEWS *****************/
  ADD_REVIEW(state, review){
    state.reviews.unshift(review)
  },
  SET_REVIEWS(state, reviews){
    state.reviews = [...reviews]
  },
  UPDATE_REVIEW(state, review){
    const dataIndex = state.reviews.findIndex((f) => f.id == review.id)
    Object.assign(state.reviews[dataIndex], review)
  },
  DELETE_REVIEW(state, reviewId){
    const ItemIndex = state.reviews.findIndex((item) => item.id == reviewId)
    state.reviews.splice(ItemIndex, 1);
  },


}

export default mutations

