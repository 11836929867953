
import axios from "@/axios.js"

export default {
  addNewUser({ commit }, user) {
    return new Promise((resolve, reject) => {
      axios.post("/users/create", user)
      .then((response) => {
          if(response.data.success){
            commit('ADD_USER', response.data.user)
          }
          resolve(response)
      }).catch((error) => { reject(error) })
    })
  },
  fetchUsers({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get("/users")
        .then((response) => {
          if(response.data.success){
            commit('SET_USERS', response.data.users)
          }
          resolve(response)
        }).catch((error) => { reject(error) })
    })
  },
  fetchRoles({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get("/users/roles")
        .then((response) => {
          if(response.data.success){
            commit('SET_ROLES', response.data.roles)
          }
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  fetchUser({ commit }, userId) {
    return new Promise((resolve, reject) => {
      axios.get(`/users/${userId}/show`)
        .then((response) => {
          if(response.data.success){
            commit('SET_USER', response.data.user)
          }
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  deleteUser({ commit }, userId) {
    return new Promise((resolve, reject) => {
      axios.delete(`/users/${userId}/delete`)
        .then((response) => {
          if(response.data.success){
            commit('DELETE_USER', userId)
          }
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  multipleAction({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post(payload.action,payload.formData)
        .then((response) => {
          resolve(response)
        }).catch((error) => { reject(error) })
    })
  },
}
