
import axios from "@/axios.js"

const actions = {

    // Vertical NavMenu
    updateVerticalNavMenuWidth({ commit }, width) {
      commit('UPDATE_VERTICAL_NAV_MENU_WIDTH', width)
    },

    // VxAutoSuggest
    updateStarredPage({ commit }, payload) {
      commit('UPDATE_STARRED_PAGE', payload)
    },

    // The Navbar
    arrangeStarredPagesLimited({ commit }, list) {
      commit('ARRANGE_STARRED_PAGES_LIMITED', list)
    },
    arrangeStarredPagesMore({ commit }, list) {
      commit('ARRANGE_STARRED_PAGES_MORE', list)
    },

    // /////////////////////////////////////////////
    // UI
    // /////////////////////////////////////////////
    toggleContentOverlay({ commit }) {
      commit('TOGGLE_CONTENT_OVERLAY')
    },
    updateTheme({ commit }, val) {
      commit('UPDATE_THEME', val)
    },
    universalFetch({ commit, rootState }, payload) {
      return new Promise((resolve, reject) => {
        axios.get(payload.action)
        .then((response) => {
            resolve(response)
        }).catch((error) => { reject(error) })
      })
    },

    // /////////////////////////////////////////////
    // User/Account
    // /////////////////////////////////////////////
    fetchActiveUser({ commit, rootState }) {
      return new Promise((resolve, reject) => {
        axios.get("/account/show")
        .then((response) => {
            if(response.data.success){
              commit('UPDATE_USER_INFO', response.data.user)
            }
            resolve(response)
        }).catch((error) => { reject(error) })
      })
    },

    logoutUser({ commit, rootState }, action) {
      commit("LOGOUT_USER");
      return new Promise((resolve, reject) => {
        axios.post(`${rootState.domain}/api/logout`)
        .then((response) => {
            resolve(response)
        }).catch((error) => { reject(error) })
      })
    },

     // /////////////////////////////////////////////
    // Chats
    // /////////////////////////////////////////////
    updateUserChatInfo({ commit, rootState }, payload) {
      let formData = new FormData()
      Object.keys(payload).forEach((index) => {
          formData.append(index, payload[index])
      })
      return new Promise((resolve, reject) => {
        axios.post(`${rootState.domain}/api/app/chats/update-info`, formData)
        .then((response) => {
            resolve(response)
        }).catch((error) => { reject(error) })
      })
    },

    recallChatCount({ commit, rootState }){
      return new Promise((resolve, reject) => {
        axios.get(`${rootState.domain}/api/app/chats/count-unread`)
        .then((response) => {
          if(response.data.success){
            commit('UPDATE_USER_UNREAD_CHAT', response.data.unread_chats)
          }else{
            console.log(response.data.error)
          }
          resolve(response)
        }).catch((error) => { reject(error) })
      })
    },

    // /////////////////////////////////////////////
    // Countries
    // /////////////////////////////////////////////
    fetchCountries({ commit, rootState }, action) {
      return new Promise((resolve, reject) => {
          axios.get(`${rootState.domain}/api/app/countries`)
          .then((response) => {
          if(response.data.success){
              commit('SET_COUNTRIES', response.data.countries)
          }
          resolve(response)
          }).catch((error) => { reject(error) })
      })
  },

  // /////////////////////////////////////////////
  // Languages
  // /////////////////////////////////////////////
  fetchLanguages({ commit, rootState }, action) {
      return new Promise((resolve, reject) => {
          axios.get(`${rootState.domain}/api/app/languages`)
          .then((response) => {
          if(response.data.success){
              commit('SET_LANGUAGES', response.data.languages)
          }
          resolve(response)
          }).catch((error) => { reject(error) })
      })
  },

  // /////////////////////////////////////////////
  // Skills
  // /////////////////////////////////////////////
  fetchSkills({ commit, rootState}, action) {
      return new Promise((resolve, reject) => {
          axios.get(`${rootState.domain}/api/app/skills`)
          .then((response) => {
          if(response.data.success){
              commit('SET_SKILLS', response.data.skills)
          }
          resolve(response)
          }).catch((error) => { reject(error) })
      })
  },

  // /////////////////////////////////////////////
  // Company Records
  // /////////////////////////////////////////////
  fetchCompanyRecord({ commit, rootState }, payload) {
    return new Promise((resolve, reject) => {
      axios.get("/settings/record")
      .then((response) => {
        if(response.data.success){
          commit('SET_COMPANY_RECORD', response.data.record)
        }
        resolve(response)
      }).catch((error) => { reject(error) })
    })
  },

  // /////////////////////////////////////////////
  // Reviews
  // /////////////////////////////////////////////
  fetchReviews({ commit}) {
    return new Promise((resolve, reject) => {
      axios.get("/reviews")
      .then((response) => {
        if(response.data.success){
          commit('SET_REVIEWS', response.data.reviews)
        }
        resolve(response)
      }).catch((error) => { reject(error) })
    })
  },
}

export default actions
