
import Vue from 'vue'
import Router from 'vue-router'
import { isUserLoggedIn } from '@/auth/utils'

Vue.use(Router)

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior () {
        return { x: 0, y: 0 }
    },
    routes: [
        {
        // =============================================================================
        // MAIN LAYOUT ROUTES - Main Routes
        // =============================================================================
            path: '',
            redirect: '/',
            component: () => import('@layouts/Main.vue'),
            children: [
                {
                    path: '/',
                    name: 'dashboard',
                    component: () => import('@views/Dashboard.vue'),
                    meta: {
                        authRequired: true,
                    }
                },
                {
                    path: '/account',
                    name: 'account',
                    component: () => import('@views/account/UserEdit.vue'),
                    meta: {
                        breadcrumb: [
                            { title: 'Home', url: '/' },
                            { title: 'Account', active: true },
                        ],
                        pageTitle: 'Profile',
                        authRequired: true,
                    },
                },
                {
                  path: '/users',
                  name: 'users',
                  component: () => import('@views/users/UserList.vue'),
                  meta: {
                      breadcrumb: [
                          { title: 'Home', url: '/' },
                          { title: 'Users', active: true },
                      ],
                      pageTitle: 'User List',
                      authRequired: true,
                  },
               },
               {
                  path: '/users/create',
                  name: 'user-create',
                  component: () => import('@views/users/add/UserCreate.vue'),
                  meta: {
                      breadcrumb: [
                          { title: 'Home', url: '/' },
                          { title: 'Users' , url: '/users' },
                          { title: 'User Create', active: true },
                      ],
                      pageTitle: 'Add User',
                      rule: 'isAdmin',
                      authRequired: true,
                  },
                },

                {
                    path: '/users/:userId(\\d+)',
                    name: 'user-show',
                    component: () => import('@views/users/UserView.vue'),
                    meta: {
                        breadcrumb: [
                            { title: 'Home', url: '/' },
                            { title: 'Users' , url: '/users' },
                            { title: 'User View', active: true },
                        ],
                        pageTitle: 'User',
                        authRequired: true,
                    },
                },
                {
                  path: '/users/:userId(\\d+)/edit',
                  name: 'user-edit',
                  component: () => import('@views/users/edit/UserEdit.vue'),
                  meta: {
                      breadcrumb: [
                          { title: 'Home', url: '/' },
                          { title: 'Users', url: '/users' },
                          { title: 'Edit', active: true },
                      ],
                      pageTitle: 'User Edit',
                      parent: 'users',
                      authRequired: true,

                  },
                },
                {
                    path: '/orders',
                    name: 'orders',
                    component: () => import('@views/orders/OrderList.vue'),
                    meta: {
                        breadcrumb: [
                            { title: 'Home', url: '/' },
                            { title: 'Orders', active: true },
                        ],
                        pageTitle: 'Orders',
                        authRequired: true,
                    },
                },
                {
                    path: '/orders/:orderId(\\d+)',
                    name: 'order-view',
                    component: () => import('@views/orders/show/OrderShow.vue'),
                    meta: {
                      breadcrumb: [
                          { title: 'Home', url: '/' },
                          { title: 'Pending', url: '/orders?status=pending' },
                          { title: 'Delivered', url: '/orders?status=delivered' },
                          { title: 'Completed', url: '/orders?status=completed' },
                          { title: 'Order View', active: true },
                      ],
                      pageTitle: 'Orders',
                      authRequired: true,
                    },
                },
                {
                  path: '/reviews',
                  name: 'reviews',
                  component: () => import('@views/reviews/Reviews.vue'),
                  meta: {
                    breadcrumb: [
                        { title: 'Home', url: '/' },
                        { title: 'Reviews', active: true },
                    ],
                    pageTitle: "Reviews",
                    no_scroll: true,
                    authRequired: true,
                  }
                },
                {
                    path: '/notifications',
                    name: 'notifications',
                    component: () => import('@views/notification/Notifications.vue'),
                    meta: {
                        breadcrumb: [
                            { title: 'Home', url: '/' },
                            { title: 'Notification List', active: true },
                        ],
                        pageTitle: 'Notifications',
                        no_scroll: true,
                        authRequired: true,
                    }
                },
                {
                  path: '/settings/coupons',
                  name: 'setting-coupon',
                  component: () => import('@views/settings/coupon/Coupon.vue'),
                  meta: {
                      breadcrumb: [
                          { title: 'Home', url: '/' },
                          { title: 'Coupons', active: true },
                      ],
                      pageTitle: 'Coupons',
                      no_scroll: true,
                      authRequired: true,
                  }

                },
                {
                  path: '/configure/skills',
                  name: 'configure-skills',
                  component: () => import('@views/configure/ConfigureSkills.vue'),
                  meta: {
                      breadcrumb: [
                          { title: 'Home', url: '/' },
                          { title: 'List of Skills', active: true },
                      ],
                      pageTitle: "Skills",
                      no_scroll: true,
                      authRequired: true,
                  }
                },
                {
                  path: '/configure/languages',
                  name: 'configure-languages',
                  component: () => import('@views/configure/ConfigureLanguages.vue'),
                  meta: {
                      breadcrumb: [
                          { title: 'Home', url: '/' },
                          { title: 'Languages', active: true },
                      ],
                      pageTitle: "Languages",
                      no_scroll: true,
                      authRequired: true,
                  }
                },
                {
                  path: '/payments',
                  name: 'payments',
                  component: () => import('@views/payments/Payments.vue'),
                  meta: {
                      breadcrumb: [
                          { title: 'Home', url: '/' },
                          { title: 'Payment List', active: true },
                      ],
                      pageTitle: 'Payments',
                      no_scroll: true,
                      authRequired: true,
                }
              },
            ]
        },
        // =============================================================================
        // FULL PAGE LAYOUTS
        // =============================================================================
        {
            path: '',
            component: () => import('@layouts/FullPage.vue'),
            children: [
                {
                    path: '/login',
                    name: 'login',
                    component: () => import('@views/pages/Login.vue'),
                    meta: {
                      guestRequired: true
                    }
                },
                {
                    path: '/error-404',
                    name: 'error-404',
                    component: () => import('@views/pages/Error404.vue'),
                },
                {
                    path: '/error-500',
                    name: 'error-500',
                    component: () => import('@views/pages/Error500.vue'),
                },
                {
                    path: '/unauthorized',
                    name: 'unauthorized',
                    component: () => import('@views/pages/Unauthorized.vue'),
                },
                {
                    path: '/maintenance',
                    name: 'maintenance',
                    component: () => import('@views/pages/Maintenance.vue'),
                },
            ]
        },
        // Redirect to 404 page, if no match found
        {
            path: '*',
            redirect: '/error-404'
        },

    ],
})

router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loader-bg')
    if (appLoading) {
        appLoading.style.display = "none";
    }
    // if(progress){
    //     progress.end();
    // }
})

router.beforeEach((to, from, next) => {
  const isLoggedIn = isUserLoggedIn()
  if(to.meta.authRequired) {
    if (!isLoggedIn) return next({path: '/login' });
  }
  if(to.meta.guestRequired) {
    if (isLoggedIn) return next({path: '/' });
  }
  return next();
});

export default router
