

export default {
  ADD_USER(state, user) {
    state.users.unshift(user)
  },
  SET_USERS(state, users) {
    state.users = users
  },
  SET_ROLES(state, roles) {
    state.roles = roles
  },
  SET_USER(state, user) {
    state.user = user
    if(state.users.length){
      const dataIndex = state.users.findIndex((u) => u.id == user.id);
      Object.assign(state.users[dataIndex], user);
    }
  },
  SET_WORKER_ORDERS(state, orders) {
    state.workerOrders = orders
  },
  DELETE_USER(state, userId) {
      state.user = {}
      const userIndex = state.users.findIndex((u) => u.id == userId)
      state.users.splice(userIndex, 1)
  },
}
